import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackabrColorClass } from '@app-shared/interfaces/snackbar-class.model';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Function that will trigger success snackbar with provided message.
   *
   * An optional duration parameter can be provided, the length of time in milliseconds to wait before automatically dismissing the snack bar. Default value is 6000ms.
   *
   * @param message string
   * @param duration number, in milliseconds (optional)
   */
  successSnackbar(message: string, duration?: number): void {
    this.translocoService
      .selectTranslate('global.Close')
      .pipe(take(1))
      .subscribe(value => {
        this._snackBar.open(message, value, {
          panelClass: SnackabrColorClass.success,
          duration: duration ?? 6000,
        });
      });
  }

  /**
   * Function that will trigger error snackbar with provided message.
   *
   * An optional duration parameter can be provided, the length of time in milliseconds to wait before automatically dismissing the snack bar. Default value is 6000ms.
   *
   * @param message string
   * @param duration number, in milliseconds (optional)
   */
  errorSnackbar(errorMessage: string, duration?: number): void {
    this.translocoService
      .selectTranslate('global.Close')
      .pipe(take(1))
      .subscribe(value => {
        this._snackBar.open(errorMessage, value, {
          panelClass: SnackabrColorClass.error,
          duration: duration ?? 6000,
        });
      });
  }

  /**
   * Function that will trigger warning snackbar with provided message.
   *
   * An optional duration parameter can be provided, the length of time in milliseconds to wait before automatically dismissing the snack bar. Default value is 6000ms.
   *
   * @param message string
   * @param duration number, in milliseconds (optional)
   */
  warningSnackbar(errorMessage: string, duration?: number): void {
    this.translocoService
      .selectTranslate('global.Close')
      .pipe(take(1))
      .subscribe(value => {
        this._snackBar.open(errorMessage, value, {
          panelClass: SnackabrColorClass.warning,
          duration: duration ?? 6000,
        });
      });
  }

  /**
   * Function that will trigger info snackbar with provided message.
   *
   * An optional duration parameter can be provided, the length of time in milliseconds to wait before automatically dismissing the snack bar. Default value is 6000ms.
   *
   * @param message string
   * @param duration number, in milliseconds (optional)
   */
  infoSnackbar(errorMessage: string, duration?: number): void {
    this.translocoService
      .selectTranslate('global.Close')
      .pipe(take(1))
      .subscribe(value => {
        this._snackBar.open(errorMessage, value, {
          panelClass: SnackabrColorClass.info,
          duration: duration ?? 6000,
        });
      });
  }
}
